import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { ErrorHandler as CustomErrorHandler } from './error-handler';
import { getTransactionId } from './transaction';
import { UtilErrorsInitConfig } from './util-errors.config';

@NgModule({
  providers: [],
})
export class UtilErrorsModule {
  // Instantiate tracing by requiring it in the module
  constructor(trace: Sentry.TraceService) {} // eslint-disable-line

  static forRoot(): ModuleWithProviders<UtilErrorsModule> {
    return {
      ngModule: UtilErrorsModule,
      providers: [
        {
          provide: ErrorHandler,
          useClass: CustomErrorHandler,
        },
        {
          provide: Sentry.TraceService,
          deps: [Router],
        },
      ],
    };
  }

  static init(config: UtilErrorsInitConfig) {
    Sentry.init({
      release: config.release,
      dsn: config.sentryDsn,
      environment: config.environment,
      integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        Sentry.browserTracingIntegration({
          // Disable request tracing as it caused CORS issues by adding a 'sentry-trace' header
          traceFetch: false,
          traceXHR: false,
        }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: config.sentryTracingSampleRate,
    });

    // Set a transaction ID to all events in the page load
    Sentry.setTag('transaction_id', getTransactionId());
  }
}

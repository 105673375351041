import { EnvironmentEnum } from '@sites/util-environment';

export const environment = {
  name: EnvironmentEnum.Production,
  production: true,
  // Replaced by actual value during build
  release: 'ffdbe65a527dd466eccdbf373d8a4d949cbc7933',

  aeUrl: 'https://ae.humanmademachine.com',
  dashboardUrl: 'https://dashboard.humanmademachine.com',
  propertyUrl: 'https://i.srvy.ninja',

  apiUrl: 'https://api.humanmademachine.com',

  authAuthority: 'https://auth.humanmademachine.com',
  authClientId: 'hmm-web-spa',
  authRedirectUrl: window.location.origin,
  authPostLogoutRedirectUrl: window.location.origin,
  authUnauthorizedRoute: '/unauthorised',

  sentryDsn:
    'https://c7d4730e60d64fea8299bce4d03f3973@o98546.ingest.sentry.io/5544684',
  sentrySampleRate: 1.0,
};
